import { ReactNode } from "react";
import { Link } from ".";

type PageHeadingProps = {
  children: ReactNode;
  to?: {
    action: string;
    label: ReactNode
  }
  form?: ReactNode
}

export function PageHeading({ children, to, form }: PageHeadingProps) {
  return (
    <div className="flex items-baseline justify-between mb-8">
      <H1>{children}</H1>
      <div>
        {to && (
          <Link to={to.action}>{to.label}</Link>
        )}
        {form}
      </div>
    </div>
  )
}

export function H1({ children }: { children: ReactNode }) {
  return <h1 className="text-3xl text-primary">{children}</h1>;
}

export function H2({ children }: { children: ReactNode }) {
  return <h2 className="text-xl">{children}</h2>;
}
